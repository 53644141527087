@tailwind base;
@tailwind components;
@tailwind utilities;

Link {
    text-decoration: none;
    color: black;
}

a {
    color: black !important;
    text-decoration: none !important;
}

a:focus {
    color: orange;
}

.dropdown-svg svg {
    color: white;
}

h5 {
    font-weight: 600 !important;
    font-size: 30px !important;
}

hr {
    border-color: #979797 !important;
}

.add-employee input, .add-employee .MuiSelect-select {
    padding: 8.5px 14px !important;
}